<template>
<div class="btn-group" >
    <ul class="nav nav-pills">
        <li>
            <ProtectedLink v-if="csv" :type="'reportCSV'" :linkType="'button'" :classes="'btn btn-default spacing_btm'" :text="'CSV'" :id="''" :name="state.report" :queryString="queryString" />
        </li>
        <li>
            <ProtectedLink v-if="pdf" :type="'reportPDF'" :linkType="'button'" :classes="'btn btn-default spacing_btm'" :text="'PDF'" :id="''" :name="state.report" :queryString="queryString" />
        </li>
        <li>
            <ProtectedLink v-if="drive" :type="'reportToDrive'" :linkType="'button'" :classes="'btn btn-default spacing_btm'" :text="'Drive'" :id="''" :name="state.report" :queryString="queryString" />
        </li>
        <li>
            <button type="button" v-if="run" class="btn btn-primary" @click="runReport"><span class="glyphicon glyphicon-search"></span></button>
        </li>
    </ul>
</div>
</template>

<script>

import cdiLocationCache from "@/cache/cdiLocation.cache";
import {store} from "@/store/Report.store";
import ProtectedLink from "@/components/utils/ProtectedLink";
import helpers from "@/store/helpers";

export default {
    name: 'Result',
    props: {
        'run': {
            default: true
        },
        'csv': {
            default: true
        },
        'pdf': {
            default: true
        },
        'drive': {
            default: true
        }
    },
    components:{
        ProtectedLink
    },
    data() {
        return {
            state: store.state,
            cache: {
                cdiLocations: cdiLocationCache.CDI_LOCATIONS
            },
        }
    },
    methods: {
        runReport: function() {
            this.populateFilters(null)
            store.runReport('table', 'slot1');
        },
        populateFilters: function(filters = null) {
            if (!filters) {
                filters = this.state.optionFields
            }
            let filterEntries = Object.entries(filters)

            this.filters = {};
            for (let i = 0; i < filterEntries.length; i++) {
                this.filters[filterEntries[i][0]] = filterEntries[i][1].val;
            }
        }
    },
    computed:{
        queryString: function() {
            var qsPairs = helpers.getQSPairs(this.state.optionFields);
            qsPairs['params[json]'] = 1;
            if (!this.state.isGroupBy) {
                qsPairs['params[is_group_by]'] = 0;
                qsPairs['params[fields]'] = this.state.data;
            }else{
                qsPairs['params[is_group_by]'] = 1;
                qsPairs['params[group_by]'] = this.state.groupBy;
            }

            if (this.state.isCompareBy) {
                qsPairs['params[date_comparison]'] = 'on';
                qsPairs['params[period_type]'] = this.state.optionFields.compareByPeriod.val;
                qsPairs['params[date_compare_periods]'] = this.state.optionFields.numPeriod.val;
            }
            return helpers.getQSString(qsPairs);

        }
    }
}

</script>
<style scoped>
div.btn-group ul.nav-pills {
    display: flex; /* use flexbox */
}
</style>