<template>
    <div class="main mobile_container_top_margin">
        <h1>Call Aheads</h1>
        <form @submit.prevent="runReport">
            <Options />
            <RptNav />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./callahead_opt";
    import RptNav from "../utils/RptNav";
    import Results from "./Results";

    export default {
        name: 'callahead',
        components: {
            Results,
            Options,
            RptNav
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    fromDate: {val: new Date().toLocaleDateString(), qsField: '[dates][scheduled_ship_from]'},
                    toDate: {val: this.endDate(), qsField: '[dates][scheduled_ship_to]'},
                    cdiLocationID: {val: '0', qsField: '[numbers][cdi_location_id]'},
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            },
            endDate() {
                let now = new Date();
                return new Date(now.setDate(now.getDate() + 7)).toLocaleDateString()
            }
        },
        created() {
            this.$appStore.setTitle(['Call Aheads']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields, true, false);
        }
    }
</script>